import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap"; // Import React-Bootstrap components
import "./styles/styles1.css";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [language, setLanguage] = useState("English"); // Language state
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  // Translation object for English and Amharic
  const translations = {
    English: {
      aboutService: "About Our Service",
      serviceDescription:
        "Explore the latest news, blogs, and detailed specifications of mobile devices. Stay updated with the latest trends in technology.",
      latestReviews: "Latest Mobile Device Reviews",
      techNews: "Tech News and Updates",
      expertBlogs: "Expert Blogs and Articles",
      deviceSpecs: "Detailed Device Specifications",
      exclusiveOffers: "Exclusive Offers and Discounts",
      learnMore: "Learn More",
      phoneNumberLabel: "Phone Number",
      pinLabel: "PIN",
      agreeTerms: "Agree to terms and conditions",
      loginButton: "LogIn",
      noAccount: "Don't have an account?",
      signUp: "Sign up here",
      serviceAgreement: "Service Agreement",
      close: "Close",
    },
    Amharic: {
      aboutService: "ስለ አገልግሎታችን",
      serviceDescription:
        "የተለያዩ የሞባይል መሣሪያዎችን ዝርዝር የምርመራዎች፣ ዜናዎች እና ጽሑፎችን ያስሱ። በቴክኖሎጂ �ስጥ ከሚኖሩ የቅርብ ጊዜ አዝማሚያዎች ጋር ይተዋወቁ።",
      latestReviews: "የቅርብ ጊዜ የሞባይል መሣሪያ ዝርዝሮች",
      techNews: "የቴክኖሎጂ ዜና እና ዝመናዎች",
      expertBlogs: "የባለሙያዎች ጽሑፎች እና መጣጥፎች",
      deviceSpecs: "ዝርዝር የመሣሪያ ዝርዝሮች",
      exclusiveOffers: "ልዩ ቅናሾች እና ቅናሾች",
      learnMore: "ተጨማሪ ይወቁ",
      phoneNumberLabel: "ስልክ ቁጥር",
      pinLabel: "ፒን",
      agreeTerms: "ውሎችን እና ሁኔታዎችን ይቀበሉ",
      loginButton: "ግባ",
      noAccount: "መለያ የሎትህም?",
      signUp: "እዚህ ይመዝገቡ",
      serviceAgreement: "የአገልግሎት ስምምነት",
      close: "ዝጋ",
    },
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formattedPhoneNumber = `251${phoneNumber}`;

      const response = await axios.post("https://admin.hunegna.com/login.php", {
        phoneNumber: formattedPhoneNumber, // Send the formatted phone number
        pin,
      });

      if (response.data.message === "Login successful") {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("loggedin", true);
        localStorage.setItem("phoneNumber", formattedPhoneNumber);
        navigate("/home");
      } else {
        setError("Invalid phone number or PIN.");
      }
    } catch (err) {
      setError("An error occurred while logging in. Please try again.");
    }
  };

  // Toggle language
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "English" ? "Amharic" : "English"));
  };

  // Get translations for the current language
  const t = translations[language];

  return (
    <div className="d-flex flex-column min-vh-100 loginsection">
      {/* Header */}
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand" href="#">
              <span className="logo-text" style={{ color: "#FFD700", fontWeight: "bold", fontSize: "1.5rem" }}>
                
              </span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto"></ul>
              <button
                id="languageToggle"
                className="btn btn-sm btn-outline-light ms-3"
                onClick={toggleLanguage}
              >
                <span id="languageText">{language}</span>
                <i className="fas fa-globe ms-2"></i>
              </button>
            </div>
          </nav>
        </div>
      </header>

      {/* Error Message Display */}
      {error && (
        <div className="alert alert-danger text-center mb-0" role="alert">
          {error}
        </div>
      )}

      {/* Main Content */}
      <main className="flex-grow-1 d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            {/* Service Content (Left Side) */}
            <div className="col-md-6 d-flex align-items-center">
              <div className="service-content p-4 text-white">
                <h2>{t.aboutService}</h2>
                <p>{t.serviceDescription}</p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fas fa-check-circle me-2"></i>{t.latestReviews}
                  </li>
                  <li>
                    <i className="fas fa-check-circle me-2"></i>{t.techNews}
                  </li>
                  <li>
                    <i className="fas fa-check-circle me-2"></i>{t.expertBlogs}
                  </li>
                  <li>
                    <i className="fas fa-check-circle me-2"></i>{t.deviceSpecs}
                  </li>
                  <li>
                    <i className="fas fa-check-circle me-2"></i>{t.exclusiveOffers}
                  </li>
                </ul>
                <a href="#" className="btn btn-outline-light">
                  {t.learnMore}
                </a>
              </div>
            </div>

            {/* Login Form (Right Side) */}
            <div className="col-md-6 d-flex align-items-center">
              <div className="login-box p-5 shadow-lg rounded">
                <form id="loginForm" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" id="phoneLabel" className="form-label">
                      {t.phoneNumberLabel}
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">+251</span>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder={t.phoneNumberLabel}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pin" id="pinLabel" className="form-label">
                      {t.pinLabel}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      placeholder={t.pinLabel}
                      required
                    />
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsCheck"
                      required
                    />
                    <label className="form-check-label" id="termsLabel" htmlFor="termsCheck">
                      {t.agreeTerms}
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary w-100 mb-3" id="loginButton">
                    {t.loginButton}
                  </button>
                  <div className="text-center">
                    <span id="noAccountText">{t.noAccount}</span>
                    <a href="#" id="signUpLink">
                      {t.signUp}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="footer text-white text-center py-3">
        <div className="container">
          <p>&copy; 2023 Hunegna. All rights reserved.</p>
          <div className="social-links">
            <a href="#" className="text-white me-3">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="text-white me-3">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="text-white me-3">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
          <Button variant="link" className="text-white" onClick={() => setShowModal(true)}>
            {t.serviceAgreement}
          </Button>
        </div>
      </footer>

      {/* Service Agreement Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t.serviceAgreement}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This is the service agreement content. You can add detailed terms and conditions here.</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque leo nec nisi tincidunt, nec
            tincidunt nisi tincidunt.
          </p>
          <p>
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;